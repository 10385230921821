/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import { GlobalStateContext } from "../../engine/GlobalState";
import { makeGradient } from "../../utils/color";
import NotificationComponent from "../NotificationComponent/NotificationComponent";
import ErrorComponent from "./ErrorComponent/ErrorComponent";
import "./HeaderComponent.css";

const HeaderComponent = (props) => {
  const context = useContext(GlobalStateContext);
  // console.log(context.agent.value.disableBranding)

  return (
    <div
      className="ACCW_HeaderComponent"
      style={{
        backgroundImage: makeGradient(
          context.style.value.mainColor,
          "linear-gradient(to right, #006cff 0%, #00bfff 100%)",
          70
        ),
      }}
    >
      <AvarsComponent />
      <NotificationComponent />
      <div className="ACCW_HeaderComponent_wrapper">
        <span
          className="ACCW_HeaderComponent_title"
          style={{
            color: context.style.value.headerTextColor
              ? context.style.value.headerTextColor
              : "#ffffff",
          }}
        >
          {context.agent.value.title}
        </span>
        {(!context.disableBranding.value ||
          context.disableBranding.value === "false") && (
          <span
            style={{
              color: context.style.value.headerTextColor
                ? context.style.value.headerTextColor
                : "#ffffff",
            }}
            className="ACCW_HeaderComponent_description"
          >
            Conversations by{" "}
            <a
              href="https://activechat.ai/"
              style={{
                color: context.style.value.headerTextColor
                  ? context.style.value.headerTextColor
                  : "#ffffff",
              }}
              className="ACCW_HeaderComponent_AC_link"
              target="_blank"
            >
              Activechat.ai
            </a>
          </span>
        )}
      </div>
      {context.disableCloseButton &&
      context.disableCloseButton === "true" ? null : (
        <CloseButtonComponent />
      )}
      {context.errorMessage.value && (
        <ErrorComponent data={context.errorMessage.value} />
      )}
    </div>
  );
};

const AvarsComponent = (props) => {
  const context = useContext(GlobalStateContext);

  return (
    <div className="ACCW_HeaderComponent_wrapper_avatar">
      <div className="ACCW_HeaderComponent_avatar_one">
        <img
          className="ACCW_HeaderComponent_avatar_img"
          alt=""
          src={context.agent.value.image}
        />
      </div>
    </div>
  );
};

const CloseButtonComponent = (props) => {
  const context = useContext(GlobalStateContext);

  let closeButton = (
    <svg
      className="ACCW_HeaderComponent_img"
      xmlns="http://www.w3.org/2000/svg"
      width="14.375"
      height="14.375"
      viewBox="0 0 14.375 14.375"
    >
      <defs>
        {/* <style>
    .cls-1 {
      fill: white;
      fill-rule: evenodd;
    }
  </style> */}
      </defs>
      <path
        id="Close"
        fill={
          context.style.value.headerTextColor
            ? context.style.value.headerTextColor
            : "white"
        }
        fillRule={"evenodd"}
        className="cls-1"
        d="M833.192,165.778l-1.414,1.414-5.793-5.792-5.605,5.6L819,165.619l5.605-5.6-5.793-5.792,1.415-1.415,5.792,5.793,5.605-5.6,1.386,1.385-5.606,5.606Z"
        transform="translate(-818.813 -152.813)"
      />
    </svg>
  );

  const isChatOpen = () => {
    // console.log('Togle chat method with value: ', !context.isChatOpen.value)
    context.isChatOpen.value
      ? window.parent.postMessage("close", "*")
      : window.parent.postMessage("open", "*");
    context.isChatOpen.set(!context.isChatOpen.value);
  };

  return (
    <div className="ACCW_HeaderComponent_i">
      <div className="ACCW_HeaderComponent_img_wrapper" onClick={isChatOpen}>
        {/* <img alt="" src={close} className="ACCW_HeaderComponent_img" /> */}
        {closeButton}
      </div>
    </div>
  );
};

export default HeaderComponent;
