import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../engine/GlobalState';
import FileUploadComponent from '../FileUploadComponent/FileUploadComponent';

const ChatButtonComponent = () => {
    const context = useContext(GlobalStateContext);

    const onClickMessegeSend = () => {
        if (context.userInput.value && String(context.userInput.value).trim()) {
            console.log(`Sending message: '${context.userInput.value}'`);
            context.sendMessage(String(context.userInput.value).trim());
        }
        context.userInput.set('');
    };

    window.SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;

    // let AudioInput;
    // if ("SpeechRecognition" in window) {
    //   AudioInput = <VoiceComponent />;
    // } else {
    //   // console.log('speech recognition API not supported')
    //   AudioInput = null;
    // }

    return (
        <div className="ACCW_FooterComponent_input_button">
            <div
                style={{
                    display: context.replies.value.length && context.lockInput.value ? 'none' : 'block',
                }}
            >
                <FileUploadComponent />
            </div>
            {/* <div className="ACCW_FooterComponent_input_button_wraper"> */}
            {/* {context.style.value.voiceInput && (
        <div
          style={{
            display:
              context.replies.value.length && context.lockInput.value
                ? "none"
                : "block",
          }}
          className="ACCW_FooterComponent_input_button_image_wraper"
        >
          {AudioInput}
        </div>
      )} */}
            <div
                style={{
                    display: context.replies.value.length && context.lockInput.value ? 'none' : 'flex',
                }}
                className="ACCW_FooterComponent_input_button_send_wraper"
                onClick={onClickMessegeSend}
            >
                <SendImg />
            </div>
            {/* </div> */}
        </div>
    );
};

// const VoiceComponent = () => {
//   const [stop, setStop] = useState(false);
//   const context = useContext(GlobalStateContext);

//   const onRead = () => {
//     let { api, updates, history } = context;
//     if (!(updates.value || []).length) return;

//     history.set((ph) => ph.concat(updates.value));
//     updates.set([]);

//     api
//       .markAsRead()
//       .then((result) => {
//         // console.log('Result of update was marked as read: ', result)
//       })
//       .catch(() => {});
//   };

//   window.SpeechRecognition =
//     window.webkitSpeechRecognition || window.SpeechRecognition;

//   let onVoice = () => {
//     context.updates.value.length !== 0 && onRead();
//     window.parent.postMessage("start_voice", "*");
//     // setStop(!stop);
//   };

//   let onVoiceStop = () => {
//     // setStop(!stop);
//     window.parent.postMessage("stop_voice", "*");
//     context.updates.value.length !== 0 && onRead();
//   };

//   let bindEvent = (element, eventName, eventHandler) => {
//     if (element.addEventListener) {
//       element.addEventListener(eventName, eventHandler, false);
//     } else if (element.attachEvent) {
//       element.attachEvent("on" + eventName, eventHandler);
//     }
//   };
//   useEffect(() => {
//     bindEvent(window, "message", function (e) {
//       if (/speachText:/gim.test(e.data)) {
//         context.userInput.set(e.data.split("speachText:")[1]);
//       }

//       if (/sendspeachText:/gim.test(e.data)) {
//         context.sendMessage(e.data.split("sendspeachText:")[1]);
//       }
//       if (e.data === "speachEnd") {
//         setStop(false);
//       }
//       if (e.data === "speachStart") {
//         setStop(true);
//       }
//     });
//   }, []);

//   useEffect(() => {
//     onRead();
//   }, [context.userInput.value]);

//   return (
//     <div className="VoiceComponent_main">
//       {stop ? (
//         <div onClick={onVoiceStop} className="VoiceComponent_main">
//           {/* <i style={{ color: '#006cff' }} className="fas fa-microphone-slash"></i> */}
//           {/* <img className="audio_anim" src={audio} /> */}
//           <AudioAnimation />
//           {/* <span className="audioStop"  >
//                             STOP
//                         </span> */}
//         </div>
//       ) : (
//         <div onClick={onVoice} className="VoiceComponent_main">
//           <i
//             style={{
//               color: context.style.value.mainColor
//                 ? context.style.value.mainColor
//                 : "#006cff",
//             }}
//             className="fas fa-microphone"
//           />
//         </div>
//       )}
//     </div>
//   );
// };

export default ChatButtonComponent;

const SendImg = () => {
    const context = useContext(GlobalStateContext);
    return (
        <svg
            className={`ACCW_FooterComponent_input_button_send_img ${context.sandAnim.value ? 'sand_scale' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="72"
            height="72"
            viewBox="0 0 24 24"
        >
            <defs>
                <path
                    id="q4c4a"
                    d="M624.6 792.4l-19.2 9.6a2.4 2.4 0 0 1-2.4-2.4v-4.8a2.4 2.4 0 0 1 2.4-2.4l19.2-2.4-19.2-2.4a2.4 2.4 0 0 1-2.4-2.4v-4.8a2.4 2.4 0 0 1 2.4-2.4l19.2 9.6c1.307.675 2.4 1.074 2.4 2.4 0 1.325-1.093 1.725-2.4 2.4z"
                />
            </defs>
            <g>
                <g transform="translate(-603 -778)">
                    <use
                        fill={context.style.value.mainColor ? context.style.value.mainColor : '#006cff'}
                        href="#q4c4a"
                    />
                </g>
            </g>
        </svg>
    );
};

// const AudioAnimation = () => {
//   const context = useContext(GlobalStateContext);
//   return (
//     <svg
//       className="audio_anim"
//       width="135"
//       height="140"
//       viewBox="0 0 135 140"
//       xmlns="http://www.w3.org/2000/svg"
//       fill={
//         context.style.value.mainColor
//           ? context.style.value.mainColor
//           : "#006cff"
//       }
//     >
//       <rect y="10" width="15" height="120" rx="6">
//         <animate
//           attributeName="height"
//           begin="0.5s"
//           dur="1s"
//           values="120;110;100;90;80;70;60;50;40;140;120"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//         <animate
//           attributeName="y"
//           begin="0.5s"
//           dur="1s"
//           values="10;15;20;25;30;35;40;45;50;0;10"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//       </rect>
//       <rect x="30" y="10" width="15" height="120" rx="6">
//         <animate
//           attributeName="height"
//           begin="0.25s"
//           dur="1s"
//           values="120;110;100;90;80;70;60;50;40;140;120"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//         <animate
//           attributeName="y"
//           begin="0.25s"
//           dur="1s"
//           values="10;15;20;25;30;35;40;45;50;0;10"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//       </rect>
//       <rect x="60" width="15" height="140" rx="6">
//         <animate
//           attributeName="height"
//           begin="0s"
//           dur="1s"
//           values="120;110;100;90;80;70;60;50;40;140;120"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//         <animate
//           attributeName="y"
//           begin="0s"
//           dur="1s"
//           values="10;15;20;25;30;35;40;45;50;0;10"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//       </rect>
//       <rect x="90" y="10" width="15" height="120" rx="6">
//         <animate
//           attributeName="height"
//           begin="0.25s"
//           dur="1s"
//           values="120;110;100;90;80;70;60;50;40;140;120"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//         <animate
//           attributeName="y"
//           begin="0.25s"
//           dur="1s"
//           values="10;15;20;25;30;35;40;45;50;0;10"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//       </rect>
//       <rect x="120" y="10" width="15" height="120" rx="6">
//         <animate
//           attributeName="height"
//           begin="0.5s"
//           dur="1s"
//           values="120;110;100;90;80;70;60;50;40;140;120"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//         <animate
//           attributeName="y"
//           begin="0.5s"
//           dur="1s"
//           values="10;15;20;25;30;35;40;45;50;0;10"
//           calcMode="linear"
//           repeatCount="indefinite"
//         />
//       </rect>
//     </svg>
//   );
// };
