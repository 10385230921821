/* eslint-disable no-useless-escape */
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import API from '../api';
import AC_ICON from '../img/AC_ICON.png';

const CIS_URL = process.env.REACT_APP_CIS_URL || window._env_.REACT_APP_CIS_URL;

const values = queryString.parse(decodeURIComponent(window.location.search));
const anonymousID = values.anonymousID;
const agentID = values.agentID;
const userID = values.userID;
const customerID = values.customerID || '';
const title = values.title;
const image = values.image;
const disableBrandingValue = values.disableBranding;
const disableCloseButton = values.disableCloseButton;
window.sessionHistory = ['bot'];

let clientInfo = {},
    settings = {};

try {
    clientInfo = JSON.parse(values.clientInfo && values.clientInfo !== 'undefined' ? values.clientInfo : '{}');
    settings = JSON.parse(values.settings && values.settings !== 'undefined' ? values.settings : '{}');
} catch (error) {
    console.error('[ACCW] Error while parsing client information and settings\n', error);
    console.log({ clientInfo: values.clientInfo, settings: values.settings });
}

const api = new API(agentID, userID, anonymousID);

/**
 * Default array sorting method to sort by custom parameter
 */
const byTimeSorting = (a, b) => {
    if (a.timestamp < b.timestamp) return -1;
    if (a.timestamp > b.timestamp) return 1;
    return 0;
};

function getCookie(name) {
    var matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const Engine = (props) => {
    // const [engineInstanceTnitiator, setengineInstanceTnitiator] = useState(props);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [userInput, setUserInput] = useState('');
    const [history, setHistory] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [agent, setAgent] = useState({ title, image: image ? image : AC_ICON });
    const [agentImg, setAgentImg] = useState(AC_ICON);
    const [typing, setTyping] = useState(undefined);
    const [replies, setReplies] = useState([]);
    const [error, setError] = useState(undefined);
    const [isHistoryLoading, setHistoryLoading] = useState(false);
    const [gradient, setGradient] = useState(false);
    const [disableBranding, setDisableBranding] = useState(disableBrandingValue);
    const [viewLastMesag, setViewLastMesag] = useState(false);
    const [styleSettings, setStyleSettings] = useState({
        messageFontSize: settings.messageFontSize || 16,
        mainColor: settings.mainColor || '#006cff',
        backgroundColor: settings.backgroundColor || '#ffffff',
        headerTextColor: settings.headerTextColor || 'black',
        textColor: settings.textColor || 'black',
        buttonTextColor: settings.buttonTextColor || '006cff',
        buttonColor: settings.buttonColor || '#ffffff',
        voiceInput: settings.voiceInput || false,
        lockText: settings.lockText || 'Please choose an option above',
    });
    const [lockInput, setLockInput] = useState(false);
    const [sandAnim, setSandAnim] = useState(false);

    const setDelivered = (id, success) => {
        setHistory((ph) =>
            ph.map((message) => {
                if (message.deliveryID === id) {
                    success ? delete message.sending : (message.sending = false);
                    message.deliveryID = undefined;
                }
                return message;
            })
        );
    };

    const toDataURL = (url) =>
        fetch(url)
            .then((response) => response.blob())
            .then(
                (blob) =>
                    new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    })
            );

    useEffect(() => {
        if (image) {
            toDataURL(image).then((dataUrl) => {
                setAgentImg(`${dataUrl}`);
            });
        }
    }, []);

    let scaleanin = () => {
        setSandAnim(true);
        setTimeout(() => {
            setSandAnim(false);
        }, 200);
    };

    const sendMessage = (text) => {
        setUserInput('');
        if (!text) return;
        setReplies([]);
        scaleanin();

        let date = Date.now();
        let deliveryID = date;

        text &&
            String(text).trim() &&
            setHistory((previousHistory) =>
                previousHistory.concat({
                    userID,
                    agentID,
                    timestamp: date,
                    from: 'user',
                    message: {
                        type: 'text',
                        payload: String(text).trim(),
                    },
                    sending: true,
                    deliveryID,
                })
            );

        api.sendMessage(String(text).trim())
            .then(() => setDelivered(deliveryID, true))
            .catch(() => setDelivered(deliveryID, false));
    };

    const sendFile = (message) => {
        setUserInput('');
        if (!message) return;
        setReplies([]);
        scaleanin();

        let date = Date.now();
        let deliveryID = date;

        setHistory((previousHistory) =>
            previousHistory.concat({
                userID,
                agentID,
                timestamp: date,
                from: 'user',
                message,
                sending: true,
                deliveryID,
            })
        );
        api.sendFile(message)
            .then(() => setDelivered(deliveryID, true))
            .catch(() => setDelivered(deliveryID, false));
    };

    const getHistory = (options = {}) => {
        options = Object.assign(options, { agentID, userID });
        return api.history.get(options);
    };

    const handleUpdates = (updates = []) => {
        api.emit('connection.established');
        updates.forEach((update = {}, i) => {
            /**
             * Setting last taken update ID state into the API module for lastID by polling
             */
            api.setLastUpdateID(update._id);

            /**
             * Handling temporal updates that should change the state
             */
            if (update.action) {
                let { type, options } = update.action;

                if (options) {
                    setLockInput(options.lock);
                } else {
                    setLockInput(false);
                }

                if (type === 'typing') {
                    return setTyping(update);
                }
                if (type === 'replies') {
                    return setReplies(update.action.payload);
                }
            }

            /**
             * Clearing all old temporal updates from the state while taking another one
             */
            setTyping();
            setReplies([]);

            setUpdates((previousUpdates) =>
                previousUpdates.some((updt) => update._id === updt._id)
                    ? previousUpdates
                    : previousUpdates.concat(update)
            );
        });
    };

    const state = {
        isChatOpen: {
            value: isChatOpen,
            set: setIsChatOpen,
        },
        userInput: {
            value: userInput,
            set: setUserInput,
        },
        history: {
            value: history,
            set: setHistory,
        },
        updates: {
            value: updates,
            set: setUpdates,
        },
        agent: {
            value: agent,
            set: setAgent,
        },
        typing: {
            value: typing,
            set: setTyping,
        },
        replies: {
            value: replies,
            set: setReplies,
        },
        errorMessage: {
            value: error,
            set: setError,
        },
        historyLoading: {
            value: isHistoryLoading,
            set: setHistoryLoading,
        },
        styleGradient: {
            value: gradient,
            set: setGradient,
        },
        disableBranding: {
            value: disableBranding,
            set: setDisableBranding,
        },
        viewMessage: {
            value: viewLastMesag,
            set: setViewLastMesag,
        },
        style: {
            value: styleSettings,
            set: setStyleSettings,
        },
        lockInput: {
            value: lockInput,
            set: setLockInput,
        },
        sandAnim: {
            value: sandAnim,
            set: setSandAnim,
        },
        agentImg,
        disableCloseButton,

        TYPING_TIMEOUT_MS: 5 * 1000,

        getHistory,
        sendMessage,
        sendFile,
        api,
    };

    /**
     * Identifying customer ID for the user
     */
    useEffect(() => {
        if (window.analytics && typeof window.analytics.user === 'function') {
            let user = window.analytics.user();

            if (user.id() !== customerID) {
                window.analytics.identify(
                    customerID,
                    {},
                    {
                        integrations: {
                            All: false,
                            'Google Analytics': true,
                            Amplitude: true,
                        },
                    }
                );
            }
        }
    }, []);

    /**
     * Filtering and sorting updates and history arrays
     * FIXME: Try with another useEffect condition, probably doesn't work because of same last update ID
     */

    useEffect(() => {
        window.parent.postMessage(window.sessionHistory.join(','), '*');
    }, [window.sessionHistory]);

    useEffect(() => {
        const middleArray = [...window.sessionHistory];
        [...history]
            .concat([...updates])
            .map(
                (el) =>
                    window.sessionHistory.length < 3 &&
                    middleArray.length < 3 &&
                    el.message.payload !== '/start' &&
                    (el.from === 'user' || el.from === 'agent') &&
                    !middleArray.includes(el.from) &&
                    !window.sessionHistory.includes(el.from) &&
                    middleArray.push(el.from)
            );
        if (window.sessionHistory.length < 3 && middleArray.length > window.sessionHistory.length)
            window.sessionHistory = [...middleArray];
    }, [history.length, updates.length, window.sessionHistory]);

    useEffect(() => {
        setHistory((ph) => ph.sort(byTimeSorting).filter((message, pos) => ph.indexOf(message) === pos));
    }, [history[history.length - 1], history.length]);

    useEffect(() => {
        setUpdates((pu) => pu.sort(byTimeSorting).filter((message, pos) => pu.indexOf(message) === pos));
    }, [(updates[updates.length - 1] || {})._id]);

    useEffect(() => {
        api.setLastNotActionUpdateID((updates[updates.length - 1] || {})._id);
    }, [updates[updates.length - 1], updates.length]);

    useEffect(() => {
        let textArray = updates.filter((item) => {
            return item.message.type === 'text';
        });

        if (
            !isChatOpen &&
            updates.length !== 0 &&
            ((textArray && textArray[textArray.length - 1]) || {})._id !== getCookie('last_massage_close_id')
        ) {
            window.parent.postMessage('open_message', '*');
            setViewLastMesag(true);
        } else {
            window.parent.postMessage('close_message', '*');
            setViewLastMesag(false);
        }
    }, [isChatOpen]);

    const [firstLoading, setFirstLoading] = useState(false);

    useEffect(() => {
        if ((props || '').includes('chat') && firstLoading) {
            if (isChatOpen) {
                window.analytics.track(
                    'Chatwidget Opened',
                    {
                        Bot_Name: title,
                        Bot_ID: agentID,
                        User_ID: userID,
                        Page_URL: clientInfo.url,
                    },
                    {
                        integrations: {
                            All: false,
                            'Google Analytics': true,
                            Amplitude: true,
                        },
                    }
                );
            } else {
                window.analytics.track(
                    'Chatwidget Closed',
                    {
                        Bot_Name: title,
                        Bot_ID: agentID,
                        User_ID: userID,
                        Page_URL: clientInfo.url,
                    },
                    {
                        integrations: {
                            All: false,
                            'Google Analytics': true,
                            Amplitude: true,
                        },
                    }
                );
            }
        }
        setFirstLoading(true);
    }, [isChatOpen]);

    useEffect(() => {
        let textArray = updates.filter((item) => {
            return item.message.type === 'text';
        });

        if (
            !isChatOpen &&
            updates.length !== 0 &&
            ((textArray && textArray[textArray.length - 1]) || {})._id !== getCookie('last_massage_close_id')
        ) {
            window.parent.postMessage('open_message', '*');
            setViewLastMesag(true);
        } else {
            setViewLastMesag(false);

            window.parent.postMessage('close_message', '*');
        }
    }, [(updates[updates.length - 1] || {})._id]);

    useEffect(() => {
        /**
         * Taking first 20 messages of history to show
         */
        api.history
            .get({ agentID, userID })
            .then((messages) => {
                setHistory((ph) => messages.concat(ph));
                if (!messages.length && (props || '').includes('chat'))
                    api.sendMessage(
                        '/start',
                        Object.assign(
                            {},
                            clientInfo,
                            {
                                _last_page_visit_title: clientInfo.title,
                                _last_page_visit_url: clientInfo.url,
                                _last_page_visit_browser: clientInfo.browser,
                                _last_page_visit_browsername: clientInfo.browserName,
                                _last_page_visit_browserplatform: clientInfo.browserPlatform,
                                _timezone: clientInfo.timezone,
                                _locale: clientInfo.userLanguage,
                            },
                            {
                                title: undefined,
                                url: undefined,
                                browser: undefined,
                                browserName: undefined,
                                browserPlatform: undefined,
                                timezone: undefined,
                                userLanguage: undefined,
                            }
                        ),
                        { hidden: true }
                    )
                        .then(() => {})
                        .catch((error) => {
                            console.log('Error while sending message: ', error);
                        });
            })
            .catch((error) => console.log('Error while taking history', error));
    }, []);

    /**
     * Polling updates with different intervals from the button and from the chat
     */
    useEffect(() => {
        api.stopPolling();
    }, [isChatOpen]);

    /**
     * Setting up API events handlers
     */
    useEffect(() => {
        const socket = io(CIS_URL);
        socket.on('connect', () => {
            socket.emit('initialize', { agentID, userID }, (error, firstUpdates) => {
                if (error) {
                    console.error('Error occurred while establishing connection');
                    console.error(error);

                    setError(Object.assign({ message: 'Unable to connect to the server' }, error));
                } else {
                    setError();
                    firstUpdates.length && handleUpdates(firstUpdates);
                }
            });
        });

        socket.on('updates', handleUpdates);

        api.on('connection.error', (error) => {
            console.error('Connection error:', error);
            setError(error);
        });

        api.on('connection.established', () => {
            setError();
        });
    }, []);

    return state;
};

export default Engine;
