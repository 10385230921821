import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../engine/GlobalState';

const PersistentButtonComponent = () => {
    const context = useContext(GlobalStateContext);

    return (
        <div>
            {context.replies.value.length && context.lockInput.value ? null : (
                <div className="ACCW_FooterComponent_persistent_button">
                    <div className="ACCW_FooterComponent_persistent_button_img_wraper">
                        {/* <img alt="" className="ACCW_FooterComponent_persistent_button_img" src={persistentIcon} /> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PersistentButtonComponent;
