import React from 'react';
import ChatButtonComponent from './ChatButtonComponent/ChatButtonComponent';
import ChatInputComponent from './ChatInputComponent/ChatInputComponent';
import './FooterComponent.css';
import PersistentButtonComponent from './PersistentButtonComponent/PersistentButtonComponent';

const FooterComponent = () => (
    <div className="ACCW_FooterComponent">
        <PersistentButtonComponent />
        <ChatInputComponent />
        <ChatButtonComponent />
    </div>
);

export default FooterComponent;
