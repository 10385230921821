/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import './MessegeButtonComponent.css';
import { GlobalStateContext } from "../../../../engine/GlobalState"



const MessegeButtonComponent = props => {
    const context = useContext(GlobalStateContext)
    let button;

    const clickButton = (button = {}) => {
        let { payload = '', title = '' } = button

        if(!window.sessionHistory.includes('user')) window.sessionHistory = [...window.sessionHistory, 'user']
        window.parent.postMessage(window.sessionHistory.join(','), '*')

        button && context.api.sendPostback(payload, title)
            .then(() => {
            })
            .catch(error => {
                console.error("Error while sending postback:", error)
            })

    }

    switch (props.databutton.type) {
        case 'postback':
            button = (
                <div className="ACCW_MessegeButtonComponent" onClick={clickButton.bind(this, props.databutton)}>
                    <span style={{color: context.style.value.buttonTextColor || '006cff', fontSize: `${context.style.value.messageFontSize}px`}} className="ACCW_MessegeButtonComponent_text" >{props.databutton.title}</span>
                </div>
            )
            break;
        case 'url':

            let parse = props.databutton.payload.match(/^(_self\|)(.+)$/si)
            if (parse) {
                button = (
                    <a className="ACCW_MessegeButtonComponent_link" href={parse[2]} target="_top"  >
                        <span style={{color: context.style.value.buttonTextColor || '006cff', fontSize: `${context.style.value.messageFontSize}px`}} className="ACCW_MessegeButtonComponent_text_link" >{props.databutton.title}</span>
                    </a>
                )
            } else {
                button = (
                    <a className="ACCW_MessegeButtonComponent_link" href={props.databutton.payload} target="_blank"  >
                        <span style={{color: context.style.value.buttonTextColor || '006cff', fontSize: `${context.style.value.messageFontSize}px`}} className="ACCW_MessegeButtonComponent_text_link" >{props.databutton.title}</span>
                    </a>
                )
            }

            break;
        case 'phone':
            button = (
                <a className="ACCW_MessegeButtonComponent_link" href={`tel:${props.databutton.payload}`} target="_blank"  >
                    <span style={{color: context.style.value.buttonTextColor || '006cff', fontSize: `${context.style.value.messageFontSize}px`}} className="ACCW_MessegeButtonComponent_text_link" >{props.databutton.title}</span>
                </a>
            )
            break;

        default:
            button = (
                <div className="ACCW_MessegeButtonComponent" onClick={clickButton.bind(this, props.databutton)}>
                    <span style={{color: context.style.value.buttonTextColor || '006cff', fontSize: `${context.style.value.messageFontSize}px`}} className="ACCW_MessegeButtonComponent_text" >{props.databutton.title}</span>
                </div> 
            )
            break;
    }
    return (
        <div className="ACCW_MessegeButtonComponent_main"
        //  onClick={()=>console.log(2)}
        style={{ background: context.style.value.buttonColor || 'white'}}
          >
            {button}
            <div className="ACCW_MessegeButtonComponent_animation" >
            </div>
        </div>
    )

}

export default MessegeButtonComponent;