import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../engine/GlobalState';
import './TypingComponent.css';

const TypingComponent = (props) => {
    const context = useContext(GlobalStateContext);
    return (
        <div
            style={{ marginTop: '5px' }}
            className={`ACCW_MessegeBotComponent_messege_wrapper_user ${
                props.stack && 'ACCW_MessegeBotComponent_messege_wrapper_user_stack'
            } ${context.styleGradient.value && 'ACCW_gradient'}`}
        >
            <div className="ACCW_MessegeBotComponent_messege_wrapper_user_gradient"></div>
            <div style={{ display: 'flex' }}>
                <div className="ACCW_MessegeBotComponent_messege_wrapper_img_wrapper">
                    <div className="ACCW_MessegeBotComponent_messege_wrapper-avatar">
                        <img alt="" src={context.agentImg} className="ACCW_MessegeBotComponent_messege_avatar" />
                    </div>
                </div>
                <div className="ACCW_MessegeBotComponent">
                    <div className="ACCW_TypingComponent">
                        <div className="ACCW_TypingComponent_point ACCW_TypingComponent_point1"></div>
                        <div className="ACCW_TypingComponent_point ACCW_TypingComponent_point2"></div>
                        <div className="ACCW_TypingComponent_point ACCW_TypingComponent_point3"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TypingComponent;
