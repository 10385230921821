import React, { useContext, useEffect } from "react";
import { GlobalStateContext } from "../../engine/GlobalState";
import { makeGradient } from "../../utils/color";
import ConversationComponent from "../ConversationComponent/ConversationComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import QuickRepliesComponent from "../QuickRepliesComponent/QuickRepliesComponent";
import "./FrameWrapperComponent.css";

let FrameWrapperComponent = (props) => {
  const context = useContext(GlobalStateContext);

  let bindEvent = (element, eventName, eventHandler) => {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + eventName, eventHandler);
    }
  };

  useEffect(() => {
    bindEvent(window, "message", function (e) {
      // console.log(e.data)
      let isOpen;

      if (e.data === "open") {
        isOpen = true;
        context.isChatOpen.set(isOpen);
      }
      if (e.data === "close") {
        isOpen = false;
        context.isChatOpen.set(isOpen);
      }
    });
  }, []);

  return (
    <div className="ACCW_FrameWrapperComponent">
      <div className="ACCW_FrameWrapperComponent_content">
        <HeaderComponent />
        <ConversationComponent />
        <QuickRepliesComponent />
      </div>
      <div
        className="ACCW_FrameWrapperComponent_fotter"
        style={{
          background: makeGradient(
            context.style.value.backgroundColor,
            "white",
            70
          ),
        }}
      >
        <FooterComponent />
      </div>
    </div>
  );
};

export default FrameWrapperComponent;
